export const useDictionaries = () => {
  const dictionaries = {
    fuel: {
      "Benzyna": ["benzyna"],
      "Diesel": ["diesel"],
      "Benzyna/Gaz": [
        "benzyna/gaz",
        "benzyna+lpg"
      ],
      "Hybryda": [
        "benzyna i elektryczny",
        "diesel | elektryczny",
        "benzyna+hybryda"
      ],
      "Elektryczny": ["elektryczny"]
    },
    gearbox: {
      "Manualna": ["manualna skrzynia biegów"],
      "Automatyczna": [
        "automatyczna skrzynia biegów",
        "bezstopniowa skrzynia biegów",
        "napęd bezpośredni, bez skrzyni biegów",
        "automatyczne sekwencyjna",
        "zautomatyzowana manualna skrzynia biegów"],
    },
    drive: {
      "Napęd przedni": ["napęd przedni"],
      "Napęd tylny": ["napęd tylny"],
      "Na wszystkie koła": [
        "na wszystkie koła",
        "napęd na 4 koła",
        "dołączany napęd na 4 koła",
        "stały napęd na 4 koła",
        "stały napęd na 4 koła off road"
      ],
    },
    body: {
      "Kombi": ["kombi"],
      "Hatchback": ["hatchback"],
      "SUV": ["suv"],
      "Sedan": ["sedan"],
      "Coupe": ["coupe"],
      "Pickup": ["pickup"],
      "Kabriolet": ["kabriolet"],
      "Dostawczy": [
        "furgon",
        "podwozie, kabina krótka",
        "kombi lcv",
        "kombi mpv",
        "skrzyniowy, kabina krótka"
      ]
    }
  }

  const revTranslate = (dictionaryName, values) => {
    const result = [];
    values = values?.pop ? values : [values]
    values.forEach((value) => {
      Object.keys(dictionaries[dictionaryName]).forEach(key => {
        if (dictionaries[dictionaryName][key].includes(value)) {
          result.push(key);
        }
      })
    })
    return result.unique();
  }

  const getDictionaries = () => {
    return dictionaries;
  }

  const getDictionariesKeys = () => {
    const result = {};
    Object.keys(dictionaries).forEach(key => result[key] = Object.keys(dictionaries[key]));
    return result;
  }

  return {
    revTranslate,
    getDictionaries,
    getDictionariesKeys,
  }
}
